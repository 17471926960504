import * as React from "react";
import { HeadFC } from "gatsby";
import { ProgramWindow } from "../components/gui/organisms/program-window/program-window";
import { StaticImage } from "gatsby-plugin-image";
import { DesktopId } from "../app/desktops";
import { DesktopLayoutFloating } from "../components/layout/desktop-layouts/desktop-layout-floating";

export default function NotFoundPage() {
  return (
    <DesktopLayoutFloating desktopId={DesktopId._404}>
      <ProgramWindow windowId="404-pic" title="404 Not Found">
        <StaticImage
          src="../images/dalle/art/emperor-computer-02.png"
          alt="Painting of an ancient Chinese emperor using a laptop computer with a concerned expression, generated by DALLE-2"
          style={{
            width: "max(200px, min(800px, 100vw - 60px, 100vh - 140px))",
          }}
        />
      </ProgramWindow>
    </DesktopLayoutFloating>
  );
}

export const Head: HeadFC = () => <title>Not found | simontang.dev</title>;
